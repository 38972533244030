import { defineStore } from "pinia";
import jwtInterceptor from "@/helpers/http";
import useMainStore from '@/store/main.store';
const useCustomersStore = defineStore({
    id: 'customersStore',
    state: () => ({
        customerById: {},
        newCustomer: {},
        customers: Array(),
        customerUsers: Array(),
        myCustomer: {},
        myCustomerUsers: Array()
    }),
    getters: {},
    actions: {
        async getCustomerUsers(customerId) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.get("api/customer/users/" + customerId)
                    .then((response) => {
                    this.customerUsers = response.data;
                    return true;
                })
                    .catch((e) => {
                    throw 'An error occurred while trying to get users in this subscription, please try again. Msg: ' + e.response.data;
                });
            }
            catch {
                throw 'An error occurred while trying to get users in this subscription, please try again.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async getSelfCustomerUsers() {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.get("api/customer/self/users")
                    .then((response) => {
                    this.myCustomerUsers = response.data;
                    return true;
                })
                    .catch((e) => {
                    throw 'An error occurred while trying to get users in this subscription, please try again. Msg: ' + e.response.data;
                });
            }
            catch {
                throw 'An error occurred while trying to get users in this subscription, please try again.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async updateCustomer(cust) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                return await jwtInterceptor.put("api/customer/", cust)
                    .then((response) => {
                    const updatedCustomer = response.data;
                    const customerToUpdated = this.customers.filter((val) => val.customerId == cust.customerId)[0];
                    Object.assign(customerToUpdated, updatedCustomer);
                    return response.data;
                })
                    .catch((e) => {
                    throw 'An error occurred while trying to update your subscription, please try again. Msg: ' + e.response.data;
                });
            }
            catch {
                throw 'An error occurred while trying to update your subscription, please try again.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async saveCustomerSetup(customerId, ownerId, defaultLargeAmountThreshold, whitelistedAccounts) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                const mdl = { customerId: customerId, ownerId: ownerId, defaultLargeAmountThreshold: defaultLargeAmountThreshold, whitelistedAccounts: whitelistedAccounts };
                return await jwtInterceptor.put("api/customer/setup", mdl)
                    .then((response) => {
                    const updatedCustomer = response.data;
                    Object.assign(this.myCustomer, updatedCustomer);
                    return response.data;
                })
                    .catch((e) => {
                    throw 'An error occurred while trying to update your subscription, please try again. Msg: ' + e.response.data;
                });
            }
            catch {
                throw 'An error occurred while trying to update your subscription, please try again.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async getCustomer(id) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.get("/api/customer/" + id)
                    .then((response) => {
                    this.customerById = response.data;
                })
                    .catch((e) => {
                    throw 'Unable to fetch customer. Msg: ' + e.response.data;
                });
            }
            catch {
                throw 'Unable to fetch customer.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async getSelfCustomer() {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.get("/api/customer/self")
                    .then((response) => {
                    this.myCustomer = response.data;
                })
                    .catch((e) => {
                    mainStore.setErrorMsg('Unable to fetch customer. Msg: ' + e.response.data);
                });
            }
            catch {
                throw 'Unable to fetch customer.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async getCustomers() {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.get("/api/customer/")
                    .then((response) => {
                    this.customers = response.data;
                })
                    .catch((e) => {
                    throw 'Unable to fetch customers. Msg: ' + e.response.data;
                });
            }
            catch {
                throw 'Unable to fetch customers.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async toggleSubscription(customerId) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                return await jwtInterceptor.put("/api/customer/toggle-subscription/" + customerId)
                    .then((response) => {
                    const updatedCustomer = response.data;
                    const customerToUpdated = this.customers.filter((val) => val.customerId == updatedCustomer.customerId)[0];
                    Object.assign(customerToUpdated, updatedCustomer);
                    return true;
                })
                    .catch((e) => {
                    throw 'Unable to toggle subscription. Msg: ' + e.response.data;
                });
            }
            catch {
                throw 'Unable to toggle subscription.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async toggleOwnSubscription() {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                return await jwtInterceptor.put("/api/customer/toggle-subscription/")
                    .then((response) => {
                    this.myCustomer = response.data;
                    return true;
                })
                    .catch((e) => {
                    throw 'Unable to toggle subscription. Msg: ' + e.response.data;
                });
            }
            catch {
                throw 'Unable to toggle subscription.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async createCustomer(customer) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.post("/api/customer/", customer)
                    .then((response) => {
                    const addedCustomer = response.data;
                    this.newCustomer = addedCustomer;
                    this.customers.push(addedCustomer);
                })
                    .catch((e) => {
                    throw 'Unable to create customer. Msg: ' + e.response.data;
                });
            }
            catch {
                throw 'Unable to create customer.';
            }
            finally {
                mainStore.busy = false;
            }
        },
    }
});
export default useCustomersStore;
